body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
  background-color: #333;
  height: 100vh;
  
}

.chat {
  flex: 1;
  overflow-y: auto;
  background-color: #333;
  color: #fff;
  padding: 60px;
  width: 40%;
  margin: auto;
  position: relative;
}

.chat-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0; /* Asegúrate de que esté detrás del contenido del chat */
}

.chat-background-logo {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px; /* Ajusta el tamaño según tus necesidades */
  height: auto;
  opacity: 0.2;
  z-index: 0;
}

.message-container {
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
}
.message-container, .bottom-container {
  position: relative;
  z-index: 1; /* Asegura que el contenido esté sobre el logo */
}
.message-client, .message-bot {
  padding: 14px;
  margin-bottom: 8px;
  border-radius: 20px;
  width: fit-content;
  max-width: 70%;
}

.message-client pre, .message-bot pre {
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.message-client {
  background-color: rgba(85, 85, 85, 0.6); /* #555 con 60% de opacidad */
  backdrop-filter: blur(5px); /* Desenfoque del fondo */
  align-self: flex-end;
}

.message-bot {
  background-color: rgba(34, 34, 34, 0.6); /* #222 con 60% de opacidad */
  backdrop-filter: blur(5px); /* Desenfoque del fondo */
  align-self: flex-start;
}

.bottom-container {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
}

.input-container {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 10px;
  padding-bottom: 30px;
  background-color: #222;
  border-radius: 20px;
  width: 50%;
  margin: auto;
  max-height: 70vh;
  box-sizing: border-box;
  overflow-y: auto;
}

.input {
  flex: 1;
  padding: 10px;
  height: fit-content;
  margin-right: 10px;
  background-color: #333;
  border-radius: 20px;
  border: none;
  color: #fff;
  font-size: 16px;
  min-height: 50px;
  box-sizing: border-box;
  resize: none;
}

button {
  background-color: #1D49A7;
  color: #fff;
  padding: 8px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  font-size: 16px; /* Tamaño de la letra, ajusta según tus preferencias */
}


/* Personalización de la scrollbar para navegadores basados en WebKit (Chrome, Safari) */
.chat::-webkit-scrollbar {
  width: 7px; /* Controla el ancho de la scrollbar */

}

.chat::-webkit-scrollbar-track {
  background: transparent; /* Fondo del track de la scrollbar */

}

.chat::-webkit-scrollbar-thumb {
  background: grey; /* Color del thumb de la scrollbar */
  border-radius: 7px; /* Bordes redondeados para el thumb */

}



.loader {
  width: 60px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side,#000 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: l7 1s infinite linear;
}
@keyframes l7 {
    33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
    50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
    66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
}